/**
 * Rounds a number to at most 2 decimal places, but only if necessary
 *
 * @param { number } number
 * @returns { number }
 */

export default function round (number: number): number {
  return Math.round((number + Number.EPSILON) * 100) / 100
}
